::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #c3e4c6; 
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

body{
  background: rgb(241, 241, 241);
}