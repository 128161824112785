@import 'modules/assets/themes/antdTheme.less';

@primary-color: rgb(0, 102, 153);
@link-color: #F2A900;

/* row data */
.ant-table-tbody>tr>td {
    height: 5px;
    padding: 12px 8px;
    font-weight: normal;
}