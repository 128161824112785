@import '~antd/dist/antd.less';

@primary-color: #51a0ef;

@menu-item-font-size: 12;
@menu-item-height: 20px;
@menu-inline-toplevel-item-height: 30px;

.ant-menu-submenu-title:hover {
  color: inherit;
}

// Desactiver le select antd transfert dans le header
span.ant-transfer-list-header-dropdown {
  display: none;
}

// /* table */
// .ant-table {
//   font-size: 12px;
// }

/* row data */
.ant-table-tbody > tr > td {
  height: 5px;
  padding: 12px 8px;
  font-weight:500;
}

/* row tags */
.my-tag {
  font-size: 13px;
}

.ant-table-thead > tr > th {
  height: 5px;
  padding: 4px;
}
